<template>
  <div class="list-wrapper" v-if="list">
    <div class="list-inner">
      <div class="list-item db9 mb6" v-for="(item, i) in list" :key="i">
        <img src="@/assets/icons/done.svg" class="list-icon dr4 mr3" />
        <p class="list-text">{{ item }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_functions.scss";
@import "../styles/_mixins.scss";

.list {
  &-wrapper {
    height: 344px;
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      height: 90px;
    }

    &::before {
      top: 0;
      bottom: 0;
      background: linear-gradient(
        180deg,
        #ffffff 12.92%,
        rgba(255, 255, 255, 0) 95.22%
      );
    }

    &::after {
      top: auto;
      bottom: 0;
      background: linear-gradient(
        0deg,
        #ffffff 12.92%,
        rgba(255, 255, 255, 0) 95.22%
      );
    }
  }

  &-inner {
    animation: 20s linear 0s infinite normal none running scrollUp;
    transform: translateY(0);
    transform-style: preserve-3d;
    backface-visibility: hidden;

    &:hover {
      animation-play-state: paused;
    }
  }

  &-item {
    display: flex;
  }

  &-icon {
    margin-top: rem(13px);
    width: rem(14px);
    height: rem(14px);

    @include mobile() {
      margin-top: rem(5px);
    }
  }

  &-text {
    margin-top: 0;
    font-weight: $font-weight-semibold;
    @include font-size(4);

    @include mobile() {
      @include font-size(2);
    }
  }
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-33.33%);
  }
}
</style>
